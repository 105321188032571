import React from 'react';
import { theme } from '../theme';
import { Footer } from '../components/Footer';
import { Heading } from '../components/Text/Heading';
import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { Transition, config } from 'react-spring';
import { Label } from '../components/Inputs/Label';
import { NavLink } from '../components/Nav/NavLink';
import { Text } from '../components/Text/Text';
import { Layout } from '../layouts';
import { Head } from '../components/Head';

export interface FourOhFourProps {
  data: GatsbySchema.Query;
}

export default class Contact extends React.Component<FourOhFourProps> {
  render() {
    return (
      <Layout>
        <Head title="Not Found" description="Page Not Found" />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <div
            css={{
              flex: 1,
              padding: '2rem',
              [theme.media.medium]: {
                margin: '6rem auto',
                padding: 0,
                width: '75%',
              },
            }}
          >
            <div css={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Heading
                  css={{
                    whiteSpace: 'nowrap',
                  }}
                  component="h1"
                >
                  OH NO.
                </Heading>
                <Text size={0}>We can't find what you're looking for.</Text>
                <div
                  css={{
                    marginTop: '2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.media.medium]: {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <div css={{ flex: 1 }}>
                    <Label>Helpful Links</Label>
                    <ul
                      css={{
                        ['& > li' as string]: {
                          marginTop: '1rem',
                        },
                      }}
                    >
                      <li>
                        <NavLink css={{ display: 'inline-block' }} to="/">
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          css={{ display: 'inline-block' }}
                          to="/open-source"
                        >
                          Open Source
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          css={{ display: 'inline-block' }}
                          to="/careers"
                        >
                          Careers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          css={{ display: 'inline-block' }}
                          to="/contact"
                        >
                          Contact
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div
                    css={{
                      flex: 1,
                      marginTop: '2rem',
                      [theme.media.medium]: {
                        marginTop: 0,
                      },
                    }}
                  >
                    <Label>Recent Posts</Label>
                    <ul
                      css={{
                        marginTop: '1rem',
                        ['& > li' as string]: {
                          marginTop: '1rem',
                        },
                      }}
                    >
                      {this.props.data.allMarkdownRemark.edges.map(
                        ({ node: post }) => (
                          <li key={post.id}>
                            <NavLink
                              css={{ display: 'inline-block' }}
                              to={`/blog/${post.frontmatter.slug}`}
                            >
                              {post.frontmatter.title}
                            </NavLink>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                css={{
                  display: 'none',
                  marginLeft: '4rem',
                  [theme.media.medium]: {
                    display: 'block',
                  },
                }}
              >
                <Transition
                  key="space"
                  from={{ transform: 'scale(1.5) rotate(45deg)' }}
                  enter={{ transform: 'scale(1) rotate(0deg)' }}
                  config={config.slow}
                >
                  {styles => (
                    <div
                      css={{
                        flex: 1,
                        width: '35vw',
                        height: '35vw',
                        clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
                        transform: styles.transform,
                      }}
                    >
                      <GatsbyImage
                        sizes={this.props.data.file.childImageSharp.sizes}
                      />
                    </div>
                  )}
                </Transition>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    file(relativePath: { eq: "space.jpg" }) {
      childImageSharp {
        sizes {
          ...GatsbyImageSharpSizes
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/blog/**" }
        frontmatter: { publish: { ne: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date
          }
        }
      }
    }
  }
`;
